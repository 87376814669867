import React, { Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CustomThemeProvider } from './pages/ThemeContext';
import Navbar from './components/Navbar';
const Home = React.lazy(() => import('./pages/Home'));
const RPMdashboard = React.lazy(() => import('./components/RPMdashboard'));
const Project = React.lazy(() => import('./pages/Project'));
const ProblemList = React.lazy(() => import('./components/ProblemList'));
const Medications = React.lazy(() => import('./components/Medications'));
const Demographics = React.lazy(() => import('./components/Demographics'));
const Conditions = React.lazy(() => import('./components/Conditions'));
const Goals = React.lazy(() => import('./components/Goals'));
const Recent = React.lazy(() => import('./components/Recent'));
const Documentation = React.lazy(() => import('./components/Documentation'));
const Resources = React.lazy(() => import('./components/Resources'));
const Vitals = React.lazy(() => import('./components/Vitals'));
const Wellness = React.lazy(() => import('./components/Wellness'));
const Tasks = React.lazy(() => import('./components/Tasks'));
const Summary = React.lazy(() => import('./components/Summary'));
const Mood = React.lazy(() => import('./components/Mood'));
const Pain = React.lazy(() => import('./components/Pain'));
const DocumentationViewer = React.lazy(() => import('./pages/DocumentationViewer'));
const Guides = React.lazy(() => import('./components/Guides'));
const Workflows = React.lazy(() => import('./components/Workflows'));
const CareInfo = React.lazy(() => import('./components/CareInfo'));
const Education = React.lazy(() => import('./components/Education'));
const CallGuide = React.lazy(() => import('./components/CallGuide'));
const Hub = React.lazy(() => import('./pages/Hub'));
const Tutorials = React.lazy(() => import('./components/Tutorials'));
const PracticePreferences = React.lazy(() => import('./components/PracticePreferences'));
const Helping = React.lazy(() => import('./components/Helping'));
const Blog = React.lazy(() => import('./components/Blog'));
const Events = React.lazy(() => import('./components/Events'));
const QAcorner = React.lazy(() => import('./components/QAcorner'));
const SpanishWorkbook = React.lazy(() => import('./components/SpanishWorkbook'));

function App() {
  return (
    <CustomThemeProvider>
      <Router>
        <CssBaseline />
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/RPMdashboard" element={<RPMdashboard />} />
            <Route path="/Project" element={<Project />} />
            <Route path="/ProblemList" element={<ProblemList />} />
            <Route path="/Medications" element={<Medications />} />
            <Route path="/Demographics" element={<Demographics />} />
            <Route path="/Conditions" element={<Conditions />} />
            <Route path="/Goals" element={<Goals />} />
            <Route path="/Recent" element={<Recent />} />
            <Route path="/Documentation" element={<Documentation />} />
            <Route path="/Resources" element={<Resources />} />
            <Route path="/Vitals" element={<Vitals />} />
            <Route path="/Wellness" element={<Wellness />} />
            <Route path="/Tasks" element={<Tasks />} />
            <Route path="/Summary" element={<Summary />} />
            <Route path="/Mood" element={<Mood />} />
            <Route path="/Pain" element={<Pain />} />
            <Route path="/DocumentationViewer" element={<DocumentationViewer />} />
            <Route path="/Guides" element={<Guides />} />
            <Route path="/Workflows" element={<Workflows />} />
            <Route path="/CareInfo" element={<CareInfo />} />
            <Route path="/Education" element={<Education />} />
            <Route path="/CallGuide" element={<CallGuide />} />
            <Route path="/Hub" element={<Hub />} />
            <Route path="/Tutorials" element={<Tutorials />} />
            <Route path="/PracticePreferences" element={<PracticePreferences />} />
            <Route path="/Helping" element={<Helping />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Events" element={<Events />} />
            <Route path="/QAcorner" element={<QAcorner />} />
            <Route path="/SpanishWorkbook" element={<SpanishWorkbook />} />
          </Routes>
        </Suspense>
      </Router>
    </CustomThemeProvider>
  );
}

export default App;
