import React, { useState } from 'react';
import {
  AppBar, Toolbar, IconButton, Tooltip, Box, useMediaQuery, Drawer, List, ListItem, ListItemText,
  useScrollTrigger, Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ThemeSwitcher from '../pages/ThemeSwitcher';

const Navbar = ({ darkMode, toggleDarkMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const navItems = [
    { title: "Home", path: "/" },
    { title: "Patient Chart: Reimagined", path: "/Project" },
    { title: "Technical Documentation", path: "/DocumentationViewer" },
    { title: "Hub", path: "/Hub" }
  ];

  const isActive = (path) => location.pathname === path;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 30,
  });

  return (
    <>
      <AppBar position="sticky" elevation={trigger ? 4 : 0} sx={{
        backgroundColor: trigger ? theme.palette.background.default : theme.palette.background.paper,
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.standard,
        }),
      }}>
        <Toolbar>
          {isMobile && (
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}
          <Box display="flex" flexGrow={1} justifyContent={isMobile ? 'flex-start' : 'space-between'} alignItems="center">
            {!isMobile && (
              <>
                {navItems.map((item, index) => (
                  <Tooltip key={index} title={item.title}>
                    <Button
                      component={RouterLink}
                      to={item.path}
                      sx={{
                        color: isActive(item.path) ? theme.palette.primary.main : theme.palette.text.secondary,
                        marginRight: theme.spacing(2),
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        }
                      }}
                    >
                      {item.title}
                    </Button>
                  </Tooltip>
                ))}
              </>
            )}
            <ThemeSwitcher />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box role="presentation" onClick={() => setDrawerOpen(false)} onKeyDown={() => setDrawerOpen(false)} sx={{ width: 250 }}>
          <List>
            {navItems.map((item, index) => (
              <ListItem button key={index} component={RouterLink} to={item.path}>
                <ListItemText primary={item.title} sx={{ color: isActive(item.path) ? theme.palette.primary.main : theme.palette.text.secondary }}>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
