import React, { createContext, useContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

export const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const CustomThemeProvider = ({ children }) => {
    const [mode, setMode] = useState('dark'); 
    const [themeType, setThemeType] = useState('default');

    const theme = useMemo(() => {
        const isHighContrast = themeType.startsWith('highContrast');
        let palette, typography, components;

        if (isHighContrast) {
            palette = {
                mode,
                primary: {
                    main: mode === 'dark' ? '#1D1D1D' : '#1D1D1D',
                    contrastText: mode === 'dark' ? '#333333' : '#FFFFFF',
                },
                secondary: {
                    main: mode === 'dark' ? '#FFFFFF' : '#1D1D1D',
                    contrastText: mode === 'dark' ? '#333333' : '#FFFFFF',
                },
                background: {
                    default: mode === 'light' ? '#f5f5f5' : '#333333',
                    paper: mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(51, 51, 51, 0.9)',
                },
                text: {
                    primary: mode === 'dark' ? '#ffffff' : '#1D1D1D',
                    secondary: mode === 'dark' ? '#9C9C9C' : '#4f4f4f',
                },
            };
        } else {
            palette = {
                mode,
                primary: {
                    main: '#8D7DE3',
                    light: '#C5BBFC',
                    dark: '#6D5EBD',
                    contrastText: '#ffffff',
                },
                secondary: {
                    main: '#63CCC7',
                    light: '#93DCD8',
                    dark: '#3CB9B3',
                    contrastText: '#000000',
                },
                success: {
                    main: '#68C497',
                    light: '#95D5B6',
                    dark: '#4D9170',
                    contrastText: '#ffffff',
                },
                info: {
                    main: '#6ED4CF',
                    light: '#A5E6E2',
                    dark: '#52BAB5',
                    contrastText: '#ffffff',
                },
                warning: {
                    main: '#FDB82E',
                    light: '#FFDD99',
                    dark: '#FFBB33',
                    contrastText: '#000000',
                },
                error: {
                    main: '#ED8294',
                    light: '#ed8294',
                    dark: '#D4556B',
                    contrastText: '#ffffff',
                },
                background: {
                    default: mode === 'light' ? '#f5f5f5' : '#333333',
                    paper: mode === 'light' ? 'rgba(245, 245, 245, 0.9)' : 'rgba(32, 32, 32, 0.9)',
                },
                text: {
                    primary: mode === 'dark' ? '#ffffff' : '#1d1d1d',
                    secondary: mode === 'dark' ? '#7D8491' : '#4f4f4f',
                },
                divider: mode === 'dark' ? '#454545' : '#BDBDBD',
            };
        }

        typography = {
            fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
            fontSize: 14,
            h1: {
                fontSize: '2.5rem',
                fontWeight: 500,
                lineHeight: 1.0,
            },
            h2: {
                fontSize: '2.25rem',
                fontWeight: 500,
                lineHeight: 1.0,
            },
            h3: {
                fontSize: '2rem',
                fontWeight: 500,
                lineHeight: 1.0,
            },
            h4: {
                fontSize: '1.75rem',
                fontWeight: 500,
                lineHeight: 1.0,
            },
            h5: {
                fontSize: '1.5rem',
                fontWeight: 500,
                lineHeight: 1.2,
            },
            h6: {
                fontSize: '1rem',
                fontWeight: 700,
                lineHeight: 1.0,
            },
            subtitle1: {
                fontSize: '0.90rem',
                lineHeight: 1.0,
                fontWeight: 700,
                padding: '2px',
            },
            subtitle2: {
                fontSize: '0.70rem',
                lineHeight: 1.0,
                fontWeight: 400,
                fontStyle: 'normal',
                padding: '4px',
                color: "text.secondary"
            },
            body1: {
                fontSize: '0.90rem',
                lineHeight: 1.0,
                fontWeight: 500,
                padding: '2px',
            },
            body2: {
                fontSize: '1rem',
                fontWeight: 700,
                lineHeight: 1.0,
                padding: '4px',
            },
            caption: {
                fontSize: '0.70rem',
                lineHeight: 1.0,
                padding: '4px',
            },
        };

        components = {
            MuiButton: {
                styleOverrides: {
                    root: {
                        marginTop: 4,
                        marginBottom: 4,
                        borderRadius: 3,

                    },
                    contained: {
                        boxShadow: 'none',
                        borderRadius: 12,
                        fontWeight: 700,
                        fontSize: '.8rem',
                        color: mode === 'dark' ? '#F5F5F5' : '#FFFFFF',
                    },
                    outlined: {
                        borderRadius: 10,
                        marginLeft: 'auto',
                        display: 'flex',
                        marginRight: 'flex-end',
                        alignItems: 'center',
                        fontSize: '.8rem',
                        fontWeight: 600,
                        margin: 4,
                        padding: 'auto',
                        borderColor: mode === 'dark' && isHighContrast ? '#FFFFFF' : 'theme.palette.primary.main',
                        color: mode === 'dark' && isHighContrast ? '#FFFFFF' : 'theme.palette.primary.main',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',

                        }

                    },
                }
            },
            MuiBox: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                        marginLeft: 'auto',
                        marginRight: 'flex-end',
                        borderRadius: 4,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.075)'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        padding: 15,
                        borderRadius: 4,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.075)',

                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        marginBottom: '8px',
                        marginTop: '8px',
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        color: isHighContrast ? '#1D1D1D' : '#1D1D1D',
                        borderRadius: 4,
                        marginRight: 'flex-end',
                        marginLeft: 'auto',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        fontSize: '.7rem',
                        padding: 'none',
                        borderColor: isHighContrast ? '#FFFFFF' : undefined,
                    }
                }
            },
            MuiListItems: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'flex-start',
                        margin: 0,
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        margin: 1,
                        padding: 1,

                    }
                }
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        borderRadius: 10,
                        boxShadow: 3,
                        margin: 1,
                        padding: 1,
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        marginTop: 1,
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        borderRadius: 10,
                        boxShadow: 3,
                        margin: 2,
                        padding: 2,
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        fontColor: mode === 'dark' && isHighContrast ? '#FFFFFF' : '#4f4f4f',

                    }
                }
            },
            MuiTabPanel: {
                styleOverrides: {
                    root: {
                        padding: 2,
                        fontColor: mode === 'dark' && isHighContrast ? '#FFFFFF' : '#4f4f4f',
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: '#333',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        border: '1px solid #fff',
                    },
                    arrow: {
                        color: '#333',
                    }
                }
            },

        };

        return createTheme({ palette, typography, components });
    }, [mode, themeType]);

    const toggleThemeMode = () => {
        setMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
    };

    const switchThemeType = (type) => {
        setThemeType(type);
    };

    return (
        <ThemeContext.Provider value={{ mode, themeType, toggleThemeMode, switchThemeType, theme }}>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </ThemeContext.Provider>
    );
};
