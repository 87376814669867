import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { useThemeContext } from '../pages/ThemeContext';

const ThemeSwitcher = () => {
    const { mode, toggleThemeMode, switchThemeType, themeType } = useThemeContext();

    const handleHighContrastToggle = () => {

        const newThemeType = themeType.startsWith('highContrast') ? 'default' : `highContrast${mode.charAt(0).toUpperCase() + mode.slice(1)}`;
        switchThemeType(newThemeType);
    };

    return (
        <ButtonGroup variant="contained" aria-label="theme selection" sx={{boxShadow: 'none'}}>
            <Button onClick={toggleThemeMode}>
                Switch to {mode === 'light' ? 'Dark' : 'Light'} Mode
            </Button>
            <Button onClick={handleHighContrastToggle}>
                {themeType.startsWith('highContrast') ? 'Normal Mode' : 'High Contrast'}
            </Button>
        </ButtonGroup>
    );
};

export default ThemeSwitcher;
